
export const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#333', // Dark gray text color for readability
        fontFamily: 'Poppins, Arial, sans-serif', // Modern, clean font
        fontSmoothing: 'antialiased',
        fontSize: '18px', // Standard readable font size
        '::placeholder': {
          color: '#999', // Light gray for placeholders
          fontStyle: 'italic', // Add a bit of style to placeholders
        },
        backgroundColor: '#f9f9f9', // Soft background for a cleaner look
        border: '1px solid #e2e2e2', // Light border for input box
        borderRadius: '8px', // Rounded corners for the input box
        padding: '12px 14px', // Padding inside the input field for better spacing
      },
      invalid: {
        color: '#fa755a', // Red color for invalid input
        iconColor: '#fa755a', // Red color for the card icon when input is invalid
      },
    },
    hidePostalCode: true, // Optionally hide postal code input if not needed
  };
  export const formStyles = {
    maxWidth: '600px',
    margin: '15px auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid #afb6ff',
  };

  export const labelStyles = {
    display: 'block',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#333',
  };

  export const inputWrapperStyles = {
    border: '1px solid #e2e2e2',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#f9f9f9',
  };

  export const buttonStyles = {
    backgroundColor: '#6772E5',
    color: '#fff',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  };

  export const errorStyles = {
    color: 'red',
    marginTop: '10px',
    fontSize: '14px',
  };