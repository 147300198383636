import { Link } from "react-router-dom"
import { API_URL, URLS } from "../../_config";
import React from "react";
import { replaceUrlParams } from "../../_common/functions";
import { APP_NAME, LOGO_URL } from "../../_config/site_urls";

const Footer = () => {
    const [cmsList, setCmsList] = React.useState<any>(null);
    const [socialUrls, setSocialUrls] = React.useState<any>(null);

    const getCmsList = async () => {
        try {
            const response = await fetch((API_URL.CMS_PAGES.CMS_LIST));
            const resp = await response.json();
            setCmsList(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setCmsList([])
        }
    }

    const getSocialUrls = async () => {
        try {
            const response = await fetch((API_URL.SOCIAL_DETAILS));
            const resp = await response.json(); 
            setSocialUrls(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setSocialUrls([])
        }
    }
    React.useEffect(() => {
        getSocialUrls()
        getCmsList()
    }, []);

    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="footer-logo">
                                <Link to={URLS.HOME}><img src={LOGO_URL} alt={APP_NAME} /></Link>
                                <ul>
                                    {
                                        socialUrls && <React.Fragment>
                                            {socialUrls?.facebook_url && <li><Link to={socialUrls?.facebook_url} target="_blank"><i className='bx bxl-facebook'></i></Link></li>}
                                            {socialUrls?.instagram_url && <li><Link to={socialUrls?.instagram_url} target="_blank"><i className='bx bxl-instagram-alt'></i></Link></li>}
                                            {socialUrls?.Linkedin_url && <li><Link to={socialUrls?.Linkedin_url} target="_blank"><i className='bx bxl-linkedin-square'></i></Link></li>}
                                            {socialUrls?.youtube_url && <li><Link to={socialUrls?.youtube_url} target="_blank"><i className='bx bxl-youtube'></i></Link></li>}
                                            {socialUrls?.twitter_url && <li><Link to={socialUrls?.twitter_url} target="_blank"><i className='bx bxl-twitter'></i></Link></li>}
                                            {/* {socialUrls?.facebook_url && <li><Link to={socialUrls?.facebook_url}><i className='bx bxl-pinterest'></i></Link></li>} */}

                                        </React.Fragment>
                                    }

                                </ul>
                                <select className="form-select" aria-label="Default select example">
                                    <option >English</option>
                                    {/* <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-12">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12" >
                                    <div className="footer-link"><h4>Quick Links</h4></div>
                                </div>

                                {
                                    cmsList && cmsList.map((cms: any, index: number) => (
                                        <div className="col-lg-3 col-md-6 col-12" key={`footer-link-${index}`}>
                                            <div className="footer-link"> 
                                                <ul>
                                                    <li key={`footer_menu_${index}`}><Link to={replaceUrlParams(URLS.CMS_PAGES.CMS_LINK, { cmsSlug: cms.slug })}>{cms.title}</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right gray-bg"><span>&copy; <script>
                var CurrentYear = new Date().getFullYear()
                document.write(CurrentYear)
            </script> PlanCrafter. All rights reserved. </span></div>
        </footer>
    )
}

export default Footer