import moment from "moment"

export const UpdateWithVariables = (url: string, data: any) => {
    if (data) {
        let dynamicParams = url.match(/:[A-Z_]*/gi)
        dynamicParams?.map(key => {
            if (key.length > 1) url = url.replace(key, data[key.replace(':', '')])
        })
    }
    return url
}


export const replaceUrlParams = (url: string, params: any) => {
    Object.keys(params).map((param) => {
        url = url.replace(`:${param}`, params[param])
    })
    return url
}


export const showDate = (dbDate: any, dateFormat: any = 'DD MMM, YYYY') => {
    return (dbDate !== '') ? moment(dbDate).format(dateFormat) : "";
}

export const proposalStatus = (status: any) => {
    if (status == 0) {
        return "Pending"
    } else if (status == 1) {
        return "Submitted"
    } else if (status == 2) {
        return "Proposal Ready"
    } else if (status == 3) {
        return "Proposal Approved"
    } else if (status == 4) {
        return "Proposal Rejected"
    } else if (status == 5) {
        return "Completed"
    } else {
        return "Pending"
    }
}
export const proposalStatusButton = (status: any) => {
    if (status == 0) {
        return "pending"
    } else if (status == 1) {
        return "submitted"
    } else if (status == 2) {
        return "proposal_ready"
    } else if (status == 3) {
        return "proposal_approved"
    } else if (status == 4) {
        return "proposal_rejected"
    } else if (status == 5) {
        return "completed"
    } else {
        return "pending"
    }
}
