import React from 'react';
import { URLS } from '../../_config';
import { Link } from 'react-router-dom';
import { useToken } from '../../_common/hooks/authHook';
import { APP_NAME, LOGO_URL } from '../../_config/site_urls';
import ThankYouFooter from '../../components/layouts/ThankYouFooter';

const PaymentThankYou: React.FC = () => {
    let tokenData: any = useToken();

    return (

        <div className="thank-you-page">
            <div className="home-icon"><Link to={URLS.HOME}><i className='bx bx-home'></i></Link>
            </div>
            <div>
                <Link to={URLS.HOME}><img src={LOGO_URL} alt={APP_NAME} /></Link>
            </div>
            <div className="thank-you-text">
                <img src="/images/img/thank-you-img.png" alt='Thank You' className='float-left' />
                <h1>Payment  <span>Completed  <b>Successfully!</b></span></h1>
                <p>Thank you for completing your payment. Please download your business plan & continue with us.</p>
                {/* <p>In the meantime, you can log in to your account to check the status of your proposal at any time. </p> */}
                <span data-aos="zoom-in" className="aos-init aos-animate">
                    <Link to={(tokenData) ? URLS.ACCOUNT.MY_BUSINESS_PLAN : URLS.HOME}>
                        <button type="button" className="master-orng-btn xl-orng-btn">{(tokenData) ? "My business plans" : "Log In to Your Account"}</button>
                    </Link>
                </span>
            </div>
            
            <ThankYouFooter />
        </div>
    );
};

export default PaymentThankYou;