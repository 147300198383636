import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/layouts/footer";
import { API_URL, URLS } from "../../_config";
import { useToken } from "../../_common/hooks/authHook";
import Nav from "../../components/layouts/nav";
import { Link, useParams } from "react-router-dom";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_common/functions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";


const MyBusinessPlanDetails = () => {
    const [myBusinessPlan, setMyBusinessPlan] = useState<any>(null);
    const [addonsIds, setAddonsIds] = useState<any>(null);
    let tokenData: any = useToken();

    const urlParams: any = useParams();
    const proposalId = urlParams?.proposalId;

    const getMyBusinessPlanDetails = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN_DETAILS + "/" + proposalId, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            console.log(resp.data);
            setMyBusinessPlan(resp.data)

            const _addonsIds = resp.data?.addons.map((addon: any) => addon.addon_id).join(',');
            setAddonsIds(_addonsIds);
        } catch (error) {
            setMyBusinessPlan([]);
            console.log('Error occurred while making the API request.');
        }
    }


    useEffect(() => {
        getMyBusinessPlanDetails();
    }, [])

    return (
        <>
            <Nav />

            <div className="body-part gray-bg mt-105">
                <div className="container text-center">
                    <Breadcrumbs title={`Create Business Plan`}
                        breadcrumbs={{
                            'Home': URLS.HOME,
                            'My Business Plan': URLS.ACCOUNT.MY_BUSINESS_PLAN,
                            'Details': '#',
                        }} />
                    {/* <h2 className="page-title">My <span>Business Plan Details</span></h2>
                    <p className="tag-line">View your Business Plan</p> */}
                    {
                        !myBusinessPlan && <p>Loading...</p>
                    }



                    {
                        myBusinessPlan && myBusinessPlan?.answers &&
                        <div id="business-plan" style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
                            <div className="profile-box" >
                                <div className="profile-box-title align-items-center">
                                    <div className="d-flex align-items-center w-100">
                                        <div className="profile-icon"><Icon name="business-card" /></div>
                                        <span>My Business Plan Details #{myBusinessPlan.proposal_no}</span>
                                    </div>
                                    <div className="align-items-center d-flex justify-content-between profile-text">
                                        {
                                            myBusinessPlan?.answered_questionnaire_pdf?.original && <div className="ms-auto">
                                                <Link to={myBusinessPlan?.answered_questionnaire_pdf?.original} title="Answered PDF" target="_blank"><button className="master-orng-btn"> Answered PDF</button></Link> </div>
                                        }
                                    </div>
                                </div>
                                <div className="text-left">
                                    <div className=" ">

                                        {myBusinessPlan?.answers.map((answer: any, index: number) => {
                                            let stepName = '';
                                            if (index === 0) {
                                                stepName = answer?.questionnaire_step?.title;
                                            } else {
                                                stepName = myBusinessPlan?.answers[index - 1]?.questionnaire_step?.title != answer?.questionnaire_step?.title ? answer?.questionnaire_step?.title : '';
                                            }

                                            return (
                                                <div key={index}>
                                                    {stepName && <div className="my-3 font-serif text-2xl d-flex orange-color">
                                                        <Icon name="price-tag" className="me-3" /> <b>{stepName}</b> </div>}

                                                    <div className="ps-5">
                                                        <div className="mt-3" style={{ textAlign: 'left' }}>   <b>{index + 1}. &nbsp;  {answer?.question?.question_text}</b> </div>

                                                        <div className="d-flex mt-1">
                                                            <Icon name="answer" className="me-2 orange-color" size={16} />

                                                            {
                                                                (answer?.answer_text) ? <div className="text-start" dangerouslySetInnerHTML={{ __html: answer?.answer_text }} /> :
                                                                    ((answer?.options.length > 0) ?
                                                                        answer?.options?.map((_option: any, _i: number) => {
                                                                            return (_i > 0 ? ', ' : '') + _option?.option.option_text
                                                                        })
                                                                        : '--')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div>
                                            <div >
                                                {
                                                    myBusinessPlan && myBusinessPlan.length === 0 && <p>No data found</p>
                                                }
                                                {
                                                    !myBusinessPlan && <p>Loading...</p>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="btn-wrap mt-5 ">
                                        <Link to={URLS.ACCOUNT.MY_BUSINESS_PLAN} className="m-2"><button className="update-btn m-2">Back</button> </Link>
                                        {
                                            (myBusinessPlan?.status == 0) &&
                                            <Link to={replaceUrlParams(URLS.BUSINESS_PLAN, { subscriptionId: myBusinessPlan?.subscription_plan_id }) + "?addons=" + addonsIds + "&pId=" + proposalId}  className="m-2"><button className="update-btn m-2">Edit</button></Link>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        myBusinessPlan && myBusinessPlan?.length == 0 &&
                        <>
                            <div className="btn-wrap mt-5">
                                <p>You don't have permission for this proposal.</p>
                                <Link to={URLS.ACCOUNT.MY_BUSINESS_PLAN}><button className="master-orng-btn xl-orng-btn mt-5">My Business Plan</button> </Link>
                            </div>
                        </>
                    }
                </div >
            </div >

            <Footer />
        </>

    )
}

export default MyBusinessPlanDetails; 