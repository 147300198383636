import { Link, useNavigate, useParams } from "react-router-dom";
import BusinessPlanLeftTab from "./BusinessPlanLeftTab";
import { API_URL, URLS } from "../../_config";
import React, { useEffect, useState } from "react";
import Nav from "../../components/layouts/nav";
import { Controller, set, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Error from "../../components/formFieldError";
import FormTexAreatInput from "../../_common/form-elements/textareaInput";
import { replaceUrlParams } from "../../_common/functions";
import Footer from "../../components/layouts/footer";
import { useToken, useUser } from "../../_common/hooks/authHook";
import './AIBtn.scss';
import Select from "react-select";
import AIAssistanceAnsRight from "./AIAssistanceAnsRight";

const BusinessPlan = () => {
    const [questionnaire, setQuestionnaire] = useState<any>(null);
    const [currentActiveTab, setCurrentActiveTab] = useState<any>(0);
    const [addonsIds, setAddonsIds] = useState<any>(null);
    const [proposalId, setProposalId] = useState<any>(null);
    const [userId, setUserId] = useState<any>(null);
    const [myBusinessPlan, setMyBusinessPlan] = useState<any>(null);
    const [aiBtnDisable, setAiBtnDisable] = useState<any>(false);
    const [aiBtnDisableId, setAiBtnDisableId] = useState<any>(0);


    let tokenData: any = useToken('business-plan');
    let userData: any = useUser();

    const navigate: any = useNavigate();
    const urlParams: any = useParams();
    const subscriptionId = urlParams?.subscriptionId;

    const queryParams = new URLSearchParams(window.location.search);
    const subsId = queryParams.get("sId");
    const _addonsIds = queryParams.get("addons");
    const _proId = queryParams.get("pId");

    const [showAiAns, setShowAiAns] = useState<any>(null);
    const [AIDataRight, setAIDataRight] = useState<any>({});
    const { control, formState: { errors }, handleSubmit, setError, register, reset, setValue, getValues } = useForm();

    const getQuestionnaire = async () => {
        try {
            const response = await fetch(API_URL.BUSINESS_PLAN.QUESTIONNAIRE + "/" + subscriptionId + "?addon=" + _addonsIds);
            const resp = await response.json();
            setQuestionnaire(resp.data)

            resp.data && resp.data.map((questionnaireItem: any, tabIndex: number) => {
                let resetData: any = {};
                questionnaireItem?.questions?.map((question: any) => {

                    const eq_userField = question?.question_in_questionnaire_step?.eq_user_detail_field;
                    if (tokenData && userData) {
                        if (eq_userField) {
                            if (eq_userField == 'full_name') {
                                setValue(`question_${question.id}`, userData?.full_name);
                            } else if (eq_userField == 'email') {
                                setValue(`question_${question.id}`, userData?.email);
                            } else if (eq_userField == 'first_name') {
                                setValue(`question_${question.id}`, userData?.first_name);
                            } else if (eq_userField == 'last_name') {
                                setValue(`question_${question.id}`, userData?.last_name);
                            } else if (eq_userField == 'phone') {
                                setValue(`question_${question.id}`, userData?.phone);
                            } else if (eq_userField == 'job_title') {
                                setValue(`question_${question.id}`, userData?.job_title);
                            } else if (eq_userField == 'department') {
                                setValue(`question_${question.id}`, userData?.department);
                            }
                        }
                    }
                })
            })
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setQuestionnaire([])
        }
    };


    const getMyBusinessPlanDetails = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN_DETAILS + "/" + _proId, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            // console.log('resp.data', resp.data.answers);
            setMyBusinessPlan(resp.data)
            setUserId(resp.data?.user_id);
            let resetValues: any = {}
            resp.data?.answers && resp.data?.answers?.map((item: any) => {
                if (item?.options?.length > 0) {
                    item?.options?.map((_optionItem: any) => {
                        resetValues[`question_${_optionItem?.option?.question_id}[option]`] = _optionItem?.option_id;
                    })
                } else {
                    resetValues[`question_${item?.question_id}`] = item?.answer_text;
                }
            })
            // console.log('resetValues====', resetValues);
            reset(resetValues);
        } catch (error) {
            console.log('Error occurred while making the API request.', error); // Handle error
            setMyBusinessPlan([])
        }
    }

    useEffect(() => {
        setAddonsIds(_addonsIds);
        getQuestionnaire();
        if (_proId) {
            setProposalId(_proId);
            getMyBusinessPlanDetails();
        }
    }, [])

    const handleTabClick = (_tabIndex: any) => {
        setCurrentActiveTab(_tabIndex)
    }


    const saveProposal = (answers: any) => {
        return new Promise((resolve, reject) => {
            let params: any = {
                "subscription_plan_id": subscriptionId,
                "questionnaire_step_id": questionnaire[currentActiveTab]?.id,
                "answers": answers,
                "addons": addonsIds ? addonsIds.split(",") : [],
            }
            if (currentActiveTab > 0 || (userId && proposalId)) {
                params.proposal_id = proposalId;
                params.user_id = userId;
            }

            fetch((proposalId) ? API_URL.BUSINESS_PLAN.PROPOSAL_UPDATE : API_URL.BUSINESS_PLAN.PROPOSAL_CREATE, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        // Handle success 
                        setUserId(resp?.data?.user?.id);
                        setProposalId(resp?.data?.proposal?.id);
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                        const errorResp = resp?.data;
                        if (errorResp) {
                            Object.keys(errorResp).map((key) => {
                                setError(`question_${key}`, {
                                    type: "manual",
                                    message: errorResp[key]
                                })
                            })
                        }
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }


    const saveFormHandler = async (data: any) => {
        questionnaire?.map((item: any, tabIndex: number) => {
            if (tabIndex == currentActiveTab) {
                let error: boolean = false;
                let params: any = []

                item?.questions?.map((question: any) => {
                    if (errors[`question_${question?.id}`]) {
                        error = true;
                    } else {

                        if (data[`question_${question?.id}`]?.option !== undefined) {
                            params.push({
                                "question_id": question?.id,
                                "answer_text": "",
                                "answer_options": data[`question_${question?.id}`]?.option ? [data[`question_${question?.id}`]?.option] : [],
                            })
                        } else {
                            params.push({
                                "question_id": question?.id,
                                "answer_text": (typeof data[`question_${question?.id}`] == "string") ? data[`question_${question?.id}`] : "",
                                "answer_options": (typeof data[`question_${question?.id}`] == "string") ? [] : data[`question_${question?.id}`],
                            })
                        }

                    }
                })

                if (!error) {
                    toast.promise(
                        saveProposal(params), {
                        loading: 'Updating...',
                        success: (msg: any) => {
                            if (currentActiveTab == 0 && userId && proposalId) {
                                navigate(replaceUrlParams(URLS.BUSINESS_PLAN, { subscriptionId: subscriptionId }) + "?addons=" + addonsIds
                                    + "&pId=" + proposalId + "&uId=" + userId);
                            }
                            if (currentActiveTab == questionnaire.length - 1) {
                                navigate(URLS.THANKYOU_PAGE);
                            } else {
                                setCurrentActiveTab(currentActiveTab + 1)
                            }
                            return <b>{msg}</b>
                        },
                        error: (msg: any) => { return <b>{msg}</b> },
                    });
                } else {
                    console.log('error=------------------', errors);
                }

            }
        })
    }

    const getRequiredStart = (data: any) => {
        const dataObjArr = data && JSON.parse(data);
        let star: boolean = false;

        // console.log('dataObjArr====', dataObjArr);
        // if (Array.isArray(dataObjArr)) {
        //     dataObjArr&&  dataObjArr.forEach((item: any) => {
        //     if (item.required) {
        //         star = true;
        //     }
        //     });
        // }

        dataObjArr && Object.entries(dataObjArr).map((value: any) => {
            if (Array.isArray(value)) {
                value && value.forEach((item: any) => {
                    if (item.required) {
                        star = true;
                    }
                });
            }
        })
        return (star) ? '*' : '';
    }

    const getFieldValidations = (data: any, questionText: string, questionId?: any) => {
        const dataObjArr = data && JSON.parse(data);
        const dataObj = dataObjArr && dataObjArr[0];
        // console.log('dataObj====', questionText, dataObj);
        let returnValidation: any = {};

        dataObjArr && Object.entries(dataObjArr).map((value: any) => {
            if (Array.isArray(value)) {
                value && value.forEach((dataObj: any) => {
                    if (dataObj && dataObj.required) {
                        returnValidation.required = dataObj.required?.message
                    }
                    if (dataObj && dataObj.max) {
                        returnValidation.maxLength = { value: dataObj.max?.value, message: dataObj.max?.message }
                    }
                    if (dataObj && dataObj.min) {
                        returnValidation.minLength = { value: dataObj.min?.value, message: dataObj.min?.message }
                    }
                    if (dataObj && dataObj.minValue) {
                        returnValidation.min = { value: dataObj.minValue?.value, message: dataObj.minValue?.message }
                    }
                    if (dataObj && dataObj.maxValue) {
                        returnValidation.max = { value: dataObj.maxValue?.value, message: dataObj.maxValue?.message }
                    }
                    if (dataObj && dataObj.email) {
                        returnValidation.pattern = { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: dataObj.email?.message }
                    }
                });
            }
        })

        // if (dataObj && dataObj.numeric) {
        //     returnValidation.pattern = { value: /^[0-9]*$/, message: dataObj.numeric?.message }
        // }
        // if (dataObj && dataObj.integer) {
        //     returnValidation.pattern = { value: /^[0-9]*$/, message: dataObj.integer?.message }
        // }
        // if (dataObj && dataObj.string) {
        //     returnValidation.pattern = { value: /^[a-zA-Z]*$/, message: dataObj.string?.message }
        // }

        console.log('====', questionText, returnValidation);

        return returnValidation
    }

    const getAIRecommendations = (question_id: any, question_text: string) => {
        setShowAiAns(true);
        setAIDataRight({ question_id: question_id, question_text: question_text });
        // window.open(URLS.AI_ASSISTANCE_ANS + "?qId=" + question_id + "&q=" + question_text, 'blank');
        // setAiBtnDisable(true);
        // setAiBtnDisableId(question_id);
        // try {
        //     const response = await fetch(API_URL.BUSINESS_PLAN.AI_SUGGESTION + "?question_id=" + question_id, {
        //         headers: { Authorization: `Bearer ${tokenData}` }
        //     });
        //     const resp = await response.json(); // Get the JSON response
        //     if (resp?.suggestion) {
        //         toast.success('AI recommendation received.');
        //         window.open(URLS.AI_ASSISTANCE_ANS, '_blank');
        //     } else {
        //         toast.error('No AI recommendation available.');
        //     }
        //     setValue(`question_${question_id}`, resp?.suggestion);
        //     setAiBtnDisableId(0);
        // } catch (error) {
        //     setAiBtnDisableId(0);
        //     console.log('Error occurred while making the API request.', error); // Handle error 
        // }
    }

    useEffect(() => {
        if (aiBtnDisable) {
            setTimeout(() => {
                setAiBtnDisable(false);
            }, 5000);
        }
    }, [aiBtnDisable])


    const handleClose = () => setShowAiAns(null);


    return (
        <>
            <Nav />
            <div className="body-part gray-bg mt-105 bp-wrap">
                <BusinessPlanLeftTab questionnaire={questionnaire} currentActiveTab={currentActiveTab} handleTabClick={handleTabClick} />
                {
                    questionnaire?.map((item: any, tabIndex: number) => {

                        if (tabIndex == currentActiveTab) {
                            return (
                                <div className={`main-part-bp `} key={`questionSection_${item.id}`}>
                                    <form onSubmit={handleSubmit(saveFormHandler)}>
                                        <h2 className="page-title text-center">
                                            {item?.title.split(" ").map((word: string, i: number) => (
                                                i === 0 ? <React.Fragment key={`questionSection_${i + word}`}>{word} </React.Fragment> : <span key={`questionSection_${i + word}`}>{word} </span>
                                            ))}
                                        </h2>
                                        <p className="tag-line text-center mb-5">{item?.description} </p>
                                        <Controller
                                            defaultValue={item?.id}
                                            control={control}
                                            name={`questionnaire_step_id[${tabIndex}]`}
                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                <input type="hidden" name={name} onChange={onChange} value={value} />
                                            )}
                                        />

                                        <div className="white-box-wrap mb-3">
                                            {item?.questions.length > 0 ? item?.questions?.map((question: any) => {
                                                if (['input_text', 'email', 'date', 'phone'].indexOf(question?.question_type?.type_name) >= 0) {
                                                    let inputType = 'text';
                                                    // if (question?.question_type?.type_name === 'email') {
                                                    //     inputType = 'email';
                                                    // } else 
                                                    if (question?.question_type?.type_name === 'number') {
                                                        inputType = 'number';
                                                    } else if (question?.question_type?.type_name === 'date') {
                                                        inputType = 'date';
                                                    }
                                                    return <div key={`input_text${question.question_text}`} className="mb-3 float-input">
                                                        <label className="form-label">{question.question_text} <span>{getRequiredStart(question?.question_in_questionnaire_step?.validations)}</span></label>
                                                        <Controller
                                                            defaultValue={""}
                                                            control={control}
                                                            name={`question_${question?.id}`}
                                                            rules={getFieldValidations(question?.question_in_questionnaire_step?.validations, question.question_text, question?.id)}

                                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                <input type={inputType} name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Enter..." spellCheck="false" data-ms-editor="true" />
                                                            )}

                                                        />

                                                        <Error error={errors[`question_${question?.id}`]} customClass="text-xs" />
                                                        {
                                                            question?.prompt != null && <div className="btn-wrap-bp justify-content-end">
                                                                <button type="button" className="solid-btn get_ai_recommendations mt-2  "
                                                                    id={`AI_btnId__${question?.id}`} onClick={() => getAIRecommendations(question?.id, question?.question_text)}
                                                                    disabled={aiBtnDisable}>
                                                                    {(aiBtnDisableId == question?.id) ? 'Generating AI Assistance...' : 'AI Assistance'}
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                } else if (question?.question_type?.type_name === 'textarea') {
                                                    return <div key={`textarea_${question.question_text}`} className="mb-3 float-input">
                                                        <label className="form-label">{question.question_text} <span>{getRequiredStart(question?.question_in_questionnaire_step?.validations)}</span></label>
                                                        <Controller
                                                            defaultValue={""}
                                                            control={control}
                                                            name={`question_${question?.id}`}
                                                            rules={getFieldValidations(question?.question_in_questionnaire_step?.validations, question.question_text)}

                                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                                <FormTexAreatInput
                                                                    name={name}
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    value={value}
                                                                    inputRef={ref}
                                                                    type="text"
                                                                    rows={4}
                                                                />
                                                            )}
                                                        />
                                                        <Error error={errors[`question_${question?.id}`]} customClass="text-xs" />

                                                        {
                                                            question?.prompt != null && <div className="btn-wrap-bp justify-content-end">
                                                                <button type="button" className="solid-btn get_ai_recommendations mt-2"
                                                                    id={`AI_btnId__${question?.id}`} onClick={() => getAIRecommendations(question?.id, question?.question_text)}
                                                                    disabled={aiBtnDisable}>
                                                                    {(aiBtnDisableId == question?.id) ? 'Generating AI Assistance...' : 'AI Assistance'}
                                                                </button>
                                                            </div>
                                                        }

                                                    </div>
                                                } else if (question?.question_type?.type_name === 'checkbox') {
                                                    return <div key={`checkbox_${question.question_text}`} className="accordion bp-accordion mb-3" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne_${question?.id}`} aria-expanded="true" aria-controls={`collapseOne_${question?.id}`}>
                                                                    {question.question_text} <span className='text-red-500'>{getRequiredStart(question?.question_in_questionnaire_step?.validations)}</span>
                                                                </button>
                                                            </h2>
                                                            <div id={`collapseOne_${question?.id}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className="check-wrap">
                                                                        {question?.options && question?.options.map((item: any) => (
                                                                            <label key={`checkbox__${item?.option_text}`} className="container-check me-5">
                                                                                {item?.option_text}
                                                                                <input type="checkbox" className="form-control" value={item?.id}
                                                                                    defaultChecked={getValues()[`question_${question?.id}[option]`] == item?.id ? true : false}
                                                                                    {...register(`question_${question?.id}[]`,
                                                                                        getFieldValidations(question?.question_in_questionnaire_step?.validations, question.question_text)
                                                                                    )} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Error error={errors[`question_${question?.id}`]} customClass="text-xs" />
                                                        </div>
                                                    </div>;
                                                } else if (question?.question_type?.type_name === 'radio' || question?.question_type?.type_name === 'dropdown') {
                                                    return <div key={`radio_${question.question_text}`} className="accordion bp-accordion mb-3" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne_${question?.id}`} aria-expanded="true" aria-controls={`collapseOne_${question?.id}`}>
                                                                    {question.question_text} <span className='text-red-500'>{getRequiredStart(question?.question_in_questionnaire_step?.validations)}</span>
                                                                </button>
                                                            </h2>
                                                            <div id={`collapseOne_${question?.id}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className="radio-wrap">
                                                                        {question?.options && question?.options.map((item: any) => (
                                                                            <label key={`radio__${item?.option_text}`} className="container-radio me-5">
                                                                                {item?.option_text}
                                                                                <input type="radio" className="form-control" value={item?.id}
                                                                                    defaultChecked={getValues()[`question_${question?.id}[option]`] == item?.id ? true : false}

                                                                                    {...register(`question_${question?.id}[option]`,
                                                                                        getFieldValidations(question?.question_in_questionnaire_step?.validations, question.question_text)
                                                                                    )} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Error error={errors[`question_${question?.id}`]} customClass="text-xs" />
                                                        </div>

                                                    </div>;
                                                } else if (question?.question_type?.type_name === 'dropdown') {
                                                    let ansDropOptions: any = [];
                                                    question?.options && question?.options.map((item: any) => (
                                                        ansDropOptions.push({ value: item?.id, label: item?.option_text })
                                                    ))
                                                    return <div key={`radio_${question.question_text}`} className="accordion bp-accordion mb-3" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne_${question?.id}`} aria-expanded="true" aria-controls={`collapseOne_${question?.id}`}>
                                                                    {question.question_text} <span className='text-red-500'>{getRequiredStart(question?.question_in_questionnaire_step?.validations)}</span>
                                                                </button>
                                                            </h2>
                                                            <div id={`collapseOne_${question?.id}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body" style={{ minHeight: '100px' }}>
                                                                    <div className="radio-wrap">
                                                                        <Controller
                                                                            defaultValue={""}
                                                                            rules={getFieldValidations(question?.question_in_questionnaire_step?.validations, question.question_text)}
                                                                            control={control}
                                                                            name={`question_${question?.id}`}
                                                                            render={({
                                                                                field: { onChange, onBlur, value, name, ref },
                                                                            }) => (
                                                                                <Select
                                                                                    name={name}
                                                                                    onChange={onChange}
                                                                                    onBlur={onBlur}
                                                                                    options={ansDropOptions}
                                                                                    value={value}
                                                                                    placeholder="Select..."
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Error error={errors[`question_${question?.id}`]} customClass="text-xs" />
                                                        </div>




                                                    </div>
                                                }
                                            }) :

                                                <div className="text-center">
                                                    <p className="text-danger mb-5">No questionnaire added!</p>
                                                </div>
                                            }
                                        </div>


                                        <div className="btn-wrap-bp">
                                            {
                                                (tabIndex === 0) ? <Link to={_proId ? URLS.ACCOUNT.MY_BUSINESS_PLAN : URLS.SUBSCRIPTION + "?id=" + subsId}>
                                                    <button type="button" className="outline-btn">Back</button>
                                                </Link>
                                                    : <button type="button" className="outline-btn" onClick={() => setCurrentActiveTab(currentActiveTab - 1)}>Back</button>
                                            }


                                            <button type="submit"
                                                // onClick={saveFormHandler}
                                                className="solid-btn">{(tabIndex + 1 === questionnaire.length) ? 'Finish' : 'Next'}</button>
                                        </div>
                                    </form>
                                </div>
                            )
                        }
                    })
                }
                {
                    questionnaire?.length == 0 &&
                    <div className="main-part-bp text-center">
                        <p className="text-danger mb-5">No questionnaire available!</p>
                        <Link to={URLS.SUBSCRIPTION} className="select_plan">Back to Subscriptions</Link>
                    </div>
                }
                {
                    questionnaire == null && <div className="main-part-bp text-center">
                        <p className="text-danger mb-5">Loading...</p> </div>
                }
            </div>

            <AIAssistanceAnsRight show={showAiAns} handleClose={handleClose} AIData={AIDataRight}/>

            <Footer />




        </>
    )
}

export default BusinessPlan;