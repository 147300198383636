import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { API_URL, URLS } from "../../_config";
import { useToken, useUser } from "../../_common/hooks/authHook";
import toast from "react-hot-toast"; 
import { useNavigate } from "react-router-dom";
import { buttonStyles, CARD_ELEMENT_OPTIONS, errorStyles, formStyles, inputWrapperStyles, labelStyles } from "./CheckoutFormStyleOptions";

const CheckoutForm = ({ propData }: any) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const userData: any = useUser();
  const tokenData: any = useToken();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate: any = useNavigate();

  const submitPayment = () => {
    return new Promise((resolve, reject) => {
      if (!stripe || !elements) {
        return;
      }
      setProcessing(true);

      const cardElement = elements.getElement(CardElement);
      const stripeData = stripe.confirmCardPayment(propData?.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: userData?.full_name,
            email: userData?.email
          },
        }
      }).then(function (result: any) {
        // Handle result.error or result.paymentIntent 
        if (result.error) {
          setError(result.error.message);
          setProcessing(false);
          reject(result.error.message);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            setProcessing(false);
            setError(null);
            resolve('Payment successful');
            // console.log('PaymentIntent==============', result.paymentIntent);

            let params = {
              status: result.paymentIntent.status,
              proposal_id: propData?.paymentProposalId,
              amount: result.paymentIntent.amount,
              currency: result.paymentIntent.currency,
              transaction_id: result.paymentIntent.id,
              payment_method: result.paymentIntent.payment_method,
              payment_method_types: result.paymentIntent.payment_method_types,
              transaction_date: new Date().toISOString(),
            }
            fetch(API_URL.PAYMENTS.STRIPE_PAYMENT_STORE, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
              body: JSON.stringify(params),
            })
              .then(response => response.json())
              .then(resp => {
                if (resp?.status == 200) { // Success 
                  resolve(resp.message);
                  // console.log('client_secret==========', resp);
                  // window.location.reload(); // Refresh the page
                  navigate(URLS.ACCOUNT.PAYMENT.THANK_YOU)
                } else { // Error
                  setError(resp?.message);
                  reject(resp.message);
                  setProcessing(false);
                }
              })
              .catch(error => { // Error
                reject(error);
                setError(error);
                setProcessing(false);
              });
          }
        }
      });
    })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    toast.promise(
      submitPayment(), {
      loading: 'Please wait...',
      success: (msg: any) => {
        return <b>{msg}</b>
      },
      error: (msg: any) => { return <b>{msg}</b> },
    });

  };


  return (
    <form onSubmit={handleSubmit} className="checkout-form" style={formStyles}>

      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="card-element" style={labelStyles}>
          Credit or Debit Card
        </label>
        <div style={inputWrapperStyles}>
          <CardElement id="card-element" className="card-element"
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
        {error && <p style={errorStyles}>{error}</p>}
      </div>

      {/* <button type="submit" className="stripePay submit-button" style={buttonStyles} disabled={!stripe || processing}>
        {processing ? 'Processing...' : `Pay Now`}
      </button> */}
      <button type="submit" style={buttonStyles} disabled={!stripe}>
        Pay ${(propData.paymentAmount / 100).toFixed(2)}
      </button>
    </form>
  );
};

export default CheckoutForm;
