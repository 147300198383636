import { Link } from "react-router-dom"
import { API_URL, URLS } from "../_config"
import Footer from "../components/layouts/footer"
import Nav from "../components/layouts/nav"
import { useEffect, useState } from "react"

const Home = () => {
  const [subscriptionPlanType, setSubscriptionPlanType] = useState<any>(null);
  const [homeContantData, setHomeContantData] = useState<any>(null);

  const getHomeContentData = async () => {
    try {
      const response = await fetch((API_URL.HOME_CONTENT_DATA));
      const resp = await response.json();
      console.log(resp.data);
      setHomeContantData(resp.data)
    } catch (error) {
      console.log('Error occurred while making the API request.');
      // Handle error
      setHomeContantData([])
    }
  }

  const getSubscriptionPlanType = async () => {
    try {
      const response = await fetch(API_URL.SUBSCRIPTION.PLAN_TYPE_LIST);
      const resp = await response.json();
      setSubscriptionPlanType(resp.data)
    } catch (error) {
      setSubscriptionPlanType([])
    }
  };

  useEffect(() => {
    getHomeContentData();
    getSubscriptionPlanType();
  }, [])

  return (
    <div className="bg-white">
      <Nav />
      <div className="banner mt-105">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-text">
                <span data-aos="fade-up">Welcome to our</span>
                <h1 data-aos="fade-up">business plan site</h1>
                <p data-aos="fade-up">We help you create professional business plans tailored to your needs. Start by selecting a business plan type.</p>
                <Link to={URLS.SUBSCRIPTION} data-aos="zoom-in"><button type="button" className="master-orng-btn xl-orng-btn">Start Your Business Plan</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-part mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="sub-heading pt-120">
                <h2 data-aos="fade-up">Select your<br /> Business Plan Type</h2>
                <span className="line-org" data-aos="fade-up"></span>
                <Link to={URLS.SUBSCRIPTION} data-aos="fade-up"><button type="button" className="master-orng-btn mt-40">Start Your Business Plan</button></Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="inner-all-boxes">
                {
                  subscriptionPlanType && subscriptionPlanType.map((plan: any, index: number) => (
                    <div className={`inner-boxes t${index + 1}`} key={index} data-aos="fade-up">
                      <div className="inner-boxes-img">
                        {/* <img src={plan.image} alt="#" /> */}
                        {
                          index === 0 ? <img src="../../../images/img/c2.svg" alt="#" />
                            : <img src="../../../images/img/c1.svg" alt="#" />
                        }

                      </div>
                      <h3>{plan.title}</h3>
                      <div dangerouslySetInnerHTML={{ __html: plan.pitch }} />

                      <Link to={URLS.SUBSCRIPTION + "?id=" + plan.id} data-aos="fade-up">
                        <img src="../../../images/img/down-arrow.svg" alt="#" className="down-in-arrow" />
                      </Link>
                    </div>
                  ))
                }
                {/* <div className="inner-boxes t1 d-none" data-aos="fade-up">
                  <div className="inner-boxes-img"><img src="../../../images/img/c1.svg" alt="#" /></div>
                  <h3>Commercial Business Plans</h3> 
                  <img src="../../../images/img/down-arrow.svg" alt="#" className="down-in-arrow" />
                </div>
                <div className="inner-boxes t2 d-none" data-aos="fade-up">
                  <div className="inner-boxes-img"><img src="../../../images/img/c2.svg" alt="#" /></div>
                  <h3>Immigration Business Plans</h3> 
                  <img src="../../../images/img/down-arrow.svg" alt="#" className="down-in-arrow" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-part gray-bg">
        <div className="container">
          {
            homeContantData && homeContantData?.how_it_work_1 && <div className="sub-heading text-center mb-50">
              <h2 data-aos="fade-up">{homeContantData?.how_it_work_1?.title}</h2>
              <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: homeContantData?.how_it_work_1?.long_description }}></p>
            </div>
          }

          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              {
                homeContantData && homeContantData?.how_it_work_2 &&
                <div className="work-box" data-aos="fade-right" data-aos-duration="2000">
                  <img src="../../../images/img/h1.svg" alt="#" />
                  <h3>{homeContantData?.how_it_work_2?.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: homeContantData?.how_it_work_2?.long_description }}></p>
                </div>
              }
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              {
                homeContantData && homeContantData?.how_it_work_3 &&
                <div className="work-box" data-aos="fade-left" data-aos-duration="2000">
                  <img src="../../../images/img/h2.svg" alt="#" />
                  <h3>{homeContantData?.how_it_work_3?.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: homeContantData?.how_it_work_3?.long_description }}></p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {
        homeContantData && homeContantData?.faq &&
        <div className="body-part mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="faq-img" data-aos="zoom-in"><img src="../../../images/img/question-img.png" alt="#" /></div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="sub-heading mb-50 text-start">
                  <span data-aos="fade-up">YOU ASK. WE ANSWER</span>
                  <h2 data-aos="fade-up">Frequently Asked<br />Questions</h2>
                </div>
                <div className="accordion faq-accordian" id="accordionExample">
                  {
                    homeContantData?.faq.map((faq: any, index: number) => (
                      <div className="accordion-item" key={`accordion-item${index}`}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                          <button className={`accordion-button ${index > 0 && 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                            {faq.question}
                          </button>
                        </h2>
                        <div id={`collapse${index}`} className={`accordion-collapse collapse ${index == 0 && 'show'}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            {faq.answer}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Footer />
    </div>

  )
}

export default Home
