import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Error from '../formFieldError';
import FormTexAreatInput from '../../_common/form-elements/textareaInput';
import "./modal.scss";
import { API_URL } from '../../_config';
import { useToken } from '../../_common/hooks/authHook';


interface props {
    show: boolean,
    planData: any,
    onClose: (status: boolean) => void
}

export default function ProposalRejectModal({ show, planData, onClose }: props) {
    const navigate = useNavigate();
    const tokenData: any = useToken();
    const {
        control,
        reset,
        formState: { errors },
        handleSubmit, watch
    } = useForm();
    const handleClose = () => {
        onClose(false);
        reset();
    };

    const rejectProposalSubmit = (params: any) => {
        return new Promise((resolve, reject) => {
            const APIURL =  API_URL.ACCOUNT.MY_BUSINESS_REJECT  
            fetch(APIURL , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
                body: JSON.stringify(params),
            }).then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) { 
                        handleClose();
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                    }
                }).catch(error => {
                    // Handle error
                    // handleClose();
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }
    const rejectProposalHandler = (data: any) => {
        const params = {
            proposal_id: planData?.id,
            rejection_reason: data.rejection_reason,
        }
        toast.promise(
            rejectProposalSubmit(params), {
            loading: 'Sending...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });

    }


    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Rejection Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="off" onSubmit={handleSubmit(rejectProposalHandler)}>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name={`rejection_reason`}
                                    rules={{ required: 'This field is required' }}

                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <FormTexAreatInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            rows={4}
                                            className='form-control'
                                            placeholder='Enter rejection reason...'
                                        />
                                    )}
                                />
                                <Error error={errors[`rejection_reason`]} customClass="text-xs" />

                            </div>
                        </div> 
                    </div>


                    <Modal.Footer>
                        <div className="btn-wrap">
                            <Button variant="secondary" className='outline-btn' onClick={handleClose}> Close </Button>
                            <Button type="submit" className='solid-btn ms-2'>Submit</Button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    )
}