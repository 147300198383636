import React from "react";
import { API_URL } from "../../_config";
import { Link } from "react-router-dom";

const ThankYouFooter = () => {

    const [socialUrls, setSocialUrls] = React.useState<any>(null);

    const getSocialUrls = async () => {
        try {
            const response = await fetch((API_URL.SOCIAL_DETAILS));
            const resp = await response.json();
            console.log(resp.data);
            setSocialUrls(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setSocialUrls([])
        }
    }
    React.useEffect(() => {
        getSocialUrls()
    }, []);
    return (
        <>
            <div className="connect-sec">
                <div className="cont-txt"><h4>Let's Connect! :</h4><span className="line-gr"></span></div>
                <div className="cont-sosial-links">
                    <ul>
                        {
                            socialUrls &&
                            <React.Fragment>
                                {socialUrls?.facebook_url && <li><Link to={socialUrls?.facebook_url} target="_blank"><i className='bx bxl-facebook'></i></Link></li>}
                                {socialUrls?.instagram_url && <li><Link to={socialUrls?.instagram_url} target="_blank"><i className='bx bxl-instagram-alt'></i></Link></li>}
                                {socialUrls?.Linkedin_url && <li><Link to={socialUrls?.Linkedin_url} target="_blank"><i className='bx bxl-linkedin-square'></i></Link></li>}
                                {socialUrls?.youtube_url && <li><Link to={socialUrls?.youtube_url} target="_blank"><i className='bx bxl-youtube'></i></Link></li>}
                                {socialUrls?.twitter_url && <li><Link to={socialUrls?.twitter_url} target="_blank"><i className='bx bxl-twitter'></i></Link></li>}
                            </React.Fragment>
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ThankYouFooter