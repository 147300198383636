import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { API_URL, URLS } from "../../_config"
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import Error from "../../components/formFieldError";
import { useAuthApi } from "../../_common/hooks/api/authApiHook";
import { APP_NAME, LOGO_URL } from "../../_config/site_urls";

const Otp = () => {
    const authApi = useAuthApi();
    const [userEmail, setUserEmail] = React.useState("");
    const navigate: any = useNavigate();

    const { control, formState: { errors }, handleSubmit, setError } = useForm();

    const submitResendOtp = (params: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.AUTH.RESEND_OTP, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: userEmail }),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }

    const resendOtpHandler = async (data: any) => {
        toast.promise(
            submitResendOtp(data), {
            loading: 'Sending OTP...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }


    const submitVerifyOtp = (params: any) => {
        return new Promise((resolve, reject) => {
            authApi.verifyOtpAndLogin(params, (message: any, resp: any) => {
                resolve(message)
            }, (message: any, data: any) => {
                reject(message)
                console.log('error Response:==', data);
                if (data) {
                    Object.keys(data).map((key) => { // key is the field name
                        setError(key, {
                            type: "manual",
                            message: data[key]
                        })
                    })
                }
            })

            // fetch(API_URL.AUTH.VERIFY_OTP, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(params),
            // })
            //     .then(response => response.json())
            //     .then(resp => {
            //         if (resp?.status == 200) {
            //             // Handle success
            //             console.log('Response:', resp);
            //             resolve(resp.message);
            //         } else {
            //             console.log('error Response:==', resp);
            //             reject(resp.message);
            //             const errorResp = resp?.data;
            //             if (errorResp) {
            //                 Object.keys(errorResp).map((key) => {
            //                     setError(key, {
            //                         type: "manual",
            //                         message: errorResp[key]
            //                     })
            //                 })
            //             }
            //         }
            //     })
            //     .catch(error => {
            //         // Handle error
            //         console.error('Error:', error);
            //         reject(error.message);
            //     });
        })
    }

    const saveFormHandler = async (data: any) => {
        data.email = userEmail;
        toast.promise(
            submitVerifyOtp(data), {
            loading: 'Verify OTP...',
            success: (msg: any) => {
                localStorage.setItem("loginEmail", '');
                navigate(URLS.ACCOUNT.MY_BUSINESS_PLAN);
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        const loginEmail = localStorage.getItem("loginEmail");
        if (loginEmail) {
            setUserEmail(loginEmail);
        }else{
            navigate(URLS.LOGIN);
        }
    }, []);

    return (
        <React.Fragment>
            <div className="login-bg"></div>
            <div className="login-box">
                <div className="login-box-left">
                    <Link to={URLS.HOME} className="logo-login">
                        <img src={LOGO_URL} alt={APP_NAME} />
                    </Link>
                    <div className="login-text otp-txt" data-aos="fade-up">
                        <h2>Hey There!</h2>
                        <p>Welcome Back.</p>
                        <p>You are just one step away to your feed</p>
                    </div>
                    <div className="sign-up-box" data-aos="fade-up">
                        <h3>Don't have an account?</h3>
                        <Link className="outline-btn-plan-craft" to={URLS.SIGN_UP}>Sign Up</Link>
                    </div>
                </div>
                <div className="login-box-right">
                    <h2>OTP <span>Verification</span></h2>
                    <div className="otp-graphics" data-aos="fade-up">
                        <img src="./images/img/otp-graphics.png" alt="" />
                    </div>
                    <div className="info-box" data-aos="fade-up">
                        We've sent a verification code to your email - {userEmail}
                    </div>
                    <form onSubmit={handleSubmit(saveFormHandler)} >
                        <div className="otp-form" data-aos="fade-up">
                            <Controller
                                defaultValue={""}
                                control={control}
                                name={`otp`}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="******" spellCheck="false" data-ms-editor="true" />
                                )}
                            />
                            <button type="button" onClick={resendOtpHandler}>Resend</button>
                            <Error error={errors[`otp`]} customClass="text-xs" />
                        </div>
                        <div className="otp-btn-wrap" data-aos="fade-up">
                            <Link to={URLS.LOGIN} className="outline-btn">Back</Link>
                            <button type="submit" className="solid-btn">Verify</button>
                        </div>
                    </form>
                </div>
            </div>


        </React.Fragment>
    )
}

export default Otp