import { Link } from "react-router-dom"
import { URLS } from "../../_config"
import { useUser } from "../../_common/hooks/authHook";
import { APP_NAME, LOGO_URL } from "../../_config/site_urls";

const Nav = () => {
    let userData: any = useUser();

    return (
        <>
            <div className="navigation">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand p-0" to={URLS.HOME}> <img src={LOGO_URL} alt={APP_NAME} /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to={URLS.HOME}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={URLS.CMS_PAGES.ABOUT_US}>About</Link>
                                </li>
                                {
                                    !userData && <li className="nav-item">
                                        <Link className="nav-link" to={URLS.CMS_PAGES.CONTACT_US}>Contact</Link>
                                    </li>
                                }

                            </ul>
                            {
                                !userData ?
                                    <div className="login">
                                        <Link to={URLS.LOGIN}><i className='bx bx-lock-alt' ></i> Login</Link>
                                    </div>
                                    :
                                    <>
                                        <div className="profile-dd dropdown">
                                            <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span><img src={userData?.avatar?.thumb} alt={`${userData?.first_name} ${userData?.last_name}`} /></span>    {userData?.first_name} {userData?.last_name}
                                            </div>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to={URLS.ACCOUNT.MY_ACCOUNT}><i className="bx bxs-user"></i> My Account</Link></li>
                                                <li><Link className="dropdown-item" to={URLS.ACCOUNT.MY_BUSINESS_PLAN}><i className="bx bxs-business"></i> My Business Plan</Link></li>
                                                <li><Link className="dropdown-item" to={URLS.ACCOUNT.TICKET.LIST}><i className="bx bxs-book-content"></i> My Tickets</Link></li>
                                                <li><Link className="dropdown-item" to={URLS.LOGOUT}><i className="bx bx-log-out"></i> Sign Out</Link></li>
                                            </ul>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Nav