import { Link, useParams } from "react-router-dom"
import { API_URL, URLS } from "../../_config"
import { APP_NAME, LOGO_URL } from "../../_config/site_urls"
import Icon from "../../components/icon"
import { useToken, useUser } from "../../_common/hooks/authHook"
import { replaceUrlParams } from "../../_common/functions"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"


const PaymentDetails = () => {
    let userData: any = useUser();
    const tokenData: any = useToken();
    const { proposalId } = useParams<{ proposalId: string }>();
    const [priceDetails, setPriceDetails] = useState<any>(null)
    const [proposalPrice, setProposalPrice] = useState<any>(null)
    const [payAmount, setPayAmount] = useState<any>(0)
    const [couponCode, setSouponCode] = useState<any>(null)
    const [couponCodeSubmit, setSouponCodeSubmit] = useState<any>(false)

    const getPriceDetails = async () => {
        try {
            const response = await fetch(replaceUrlParams(API_URL.ACCOUNT.MY_BUSINESS_PLAN_PRICE_DETAILS, { proposalId: proposalId }));
            const resp = await response.json();
            console.log(resp.data);
            setPriceDetails(resp.data)
            setProposalPrice(resp.data?.total_price)
            setPayAmount(resp.data?.total_price)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setPriceDetails([])
        }
    }

    const applyCouponCode = () => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.PAYMENTS.APPLY_COUPON_CODE, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
                body: JSON.stringify({ proposal_id: proposalId, coupon_code: couponCode }),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) { // Success  
                        resolve(resp.message);
                        setSouponCodeSubmit(true)
                        setPayAmount(resp?.data.after_discount_price);
                    } else {
                        reject(resp.message);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        })
    }


    const applyCouponCodeHandler = async () => {
        toast.promise(
            applyCouponCode(), {
            loading: 'Applying coupon code...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        getPriceDetails()
    }, [])

    return (
        <>
            <div className="login-bg"></div>

            <div className="payment-box">
                <Link to={URLS.ACCOUNT.MY_BUSINESS_PLAN} className="cancel-btn"><Icon name="arrow-left" /></Link>
                <div className="payment-left-box">
                    <div className="env-box mb-20">
                        <span><i className='bx bx-envelope'></i> {userData?.email}</span>
                        <i className='bx bxs-check-circle'></i>
                    </div>
                    <table>
                        <tbody>
                            <tr className="bb-1">
                                <td colSpan={2}>
                                    <h4>{priceDetails?.subsciption_plan?.subscription_plan_type?.title}</h4>
                                 
                                    <h5><Icon name="business-card" /> {priceDetails?.subsciption_plan?.title}</h5>
                                </td>
                            </tr>
                            <tr className="bb-1">
                                <td colSpan={2}>
                                    <div className="subscription_planlist">
                                        <ul>
                                            {priceDetails?.feature.map((_featureItem: any, _featureIndex: number) => (
                                                <li key={`features${_featureIndex}`}>{_featureItem.description}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="payment-right-box">
                    {
                        priceDetails == null ? <p>Loading...</p>

                            :
                            <div className="summery-table mt-4">
                                <h3>Summary</h3>
                                <table>
                                    <tbody>
                                        {
                                            priceDetails?.subsciption_plan && <tr className="bb-1">
                                                <td>
                                                    <span className="table-body-txt">{priceDetails?.subsciption_plan?.title}</span>
                                                    {/* <h5>Addons</h5> */}
                                                </td>
                                                <td className="text-end">
                                                    <span className="table-body-txt">${priceDetails?.subsciption_plan?.price > 0 ? priceDetails?.subsciption_plan?.price : '0.00'}</span>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            priceDetails?.addon?.length > 0 &&
                                            priceDetails?.addon?.map((addonData: any) => {
                                                return <tr className="bb-1">
                                                    <td>
                                                        <span className="table-body-txt">{addonData?.title}</span>
                                                        <h5>Addons</h5>
                                                    </td>
                                                    <td className="text-end">
                                                        <span className="table-body-txt">${addonData?.price > 0 ? addonData?.price : '0.00'}</span>
                                                    </td>
                                                </tr>
                                            })
                                        }


                                        <tr className="bb-1">
                                            <td>
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Coupon Code  </label>
                                                    <input type="coupon_code" className="form-control" placeholder="ZLO3aYxA"
                                                        onChange={(e: any) => {setSouponCode(e.target.value); setSouponCodeSubmit(false)}} /> 
                                                </div>
                                            </td>
                                            <td className="text-end"><button type="button" className="" disabled={couponCodeSubmit}
                                                onClick={applyCouponCodeHandler}>{couponCodeSubmit? 'Applyed' : 'Apply Coupon'}</button></td>
                                        </tr>
                                        {
                                            proposalPrice > payAmount && <tr className="bb-1">
                                                <td><span className="table-body-txt"><b>Coupon Code Discount</b></span>
                                                {/* <h5>For coupon code</h5> */}
                                                </td>
                                                <td className="text-end"><span className="table-body-txt">${(proposalPrice - payAmount).toFixed(2)}</span></td>
                                            </tr>
                                        }
                                        <tr className="bb-1">
                                            <td><span className="table-body-txt"><b>Total Cost</b></span></td>
                                            <td className="text-end"><span className="table-body-txt">${parseFloat(payAmount).toFixed(2)}</span></td>
                                        </tr>

                                    </tbody>
                                </table>
                                <Link to={replaceUrlParams(URLS.ACCOUNT.PAYMENT.PAY, { proposalId: proposalId })}>
                                    <button type="button" className="master-orng-btn mt-4 w-100">Pay ${parseFloat(payAmount).toFixed(2)}</button>
                                </Link>
                                {/* <p>Powered by <img src="./images/img/strip.svg" alt="" /></p> */}
                            </div>

                    }

                    <div className="summery-logo">
                        <img src={LOGO_URL} alt={APP_NAME} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentDetails