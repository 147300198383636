import React, { useState } from 'react';
import { API_URL, URLS } from '../../_config';
import { Link } from 'react-router-dom';
import { APP_NAME, LOGO_URL } from '../../_config/site_urls';
import ThankYouFooter from '../../components/layouts/ThankYouFooter';
import toast from 'react-hot-toast';
import { Controller, useForm } from 'react-hook-form';
import FormTexAreatInput from '../../_common/form-elements/textareaInput';
import "./AIAssistanceAns.scss";
import "./AIBtn.scss";

const AIAssistanceAns: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const question_id = queryParams.get("qId");
    const question_txt = queryParams.get("q");

    const { control, setValue } = useForm();

    const [aiBtnDisable, setAiBtnDisable] = useState<any>(false);

    const getAIRecommendations = (questionId: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.BUSINESS_PLAN.AI_SUGGESTION + "?question_id=" + questionId, {
                headers: { 'Content-Type': 'application/json', },
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.suggestion) {
                        resolve('AI recommendation received.');
                        setValue(`ai_ans_text`, resp?.suggestion);
                    } else {
                        reject('No AI recommendation available. Please try again later.');
                        // setValue(`ai_ans_text`, '');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }
    const getAIRecommendationsHandler = async (questionId: any) => {
        setAiBtnDisable(true);
        toast.promise(
            getAIRecommendations(questionId), {
            loading: 'Data generating...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    React.useEffect(() => {
        getAIRecommendationsHandler(question_id);
    }, []);

    React.useEffect(() => {
        if (aiBtnDisable) {
            setTimeout(() => {
                setAiBtnDisable(false);
            }, 5000);
        }
    }, [aiBtnDisable])

    return (
        <div className="thank-you-page AIAssistanceAns_page">
            <div className="home-icon"><Link to={URLS.HOME}><i className='bx bx-home'></i></Link>
            </div>
            <div>
                <Link to={URLS.HOME}><img src={LOGO_URL} alt={APP_NAME} /></Link>
            </div>
            <div className="thank-you-text AIAssistanceAns_content mb-5" >
                <h1>AI   <span>Assistance  </span></h1>
                <div key={`ai_ans_text`} className="mb-3 float-input w-100">
                    <label className="form-label">{question_txt}</label>
                    <Controller
                        defaultValue={""}
                        control={control}
                        name={`ai_ans_text`}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <FormTexAreatInput
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                type="text"
                                rows={40}
                                placeholder='Please wait for AI recommendation...'
                            />
                        )}
                    />

                    <div className="btn-wrap-bp justify-content-end">
                        <button type="button" className="solid-btn mt-2 get_ai_recommendations"
                            id={`AI_btnId__${question_id}`} onClick={() => getAIRecommendationsHandler(question_id)}
                            disabled={aiBtnDisable}>
                            {(aiBtnDisable) ? 'Generating AI Assistance...' : ' AI Assistance  '}
                        </button>
                    </div>
                </div>
            </div>

            <ThankYouFooter />
        </div>
    );
};

export default AIAssistanceAns;