import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { API_URL } from "../../_config";
import FormTexAreatInput from "../../_common/form-elements/textareaInput";

interface props {
    show: any
    handleClose: any
    AIData: any
}
const AIAssistanceAnsRight = ({ show, handleClose, AIData }: props) => {
    const { control, setValue } = useForm();

    const question_id = AIData?.question_id;
    const question_txt = AIData?.question_text;
    const [aiBtnDisable, setAiBtnDisable] = useState<any>(false);

    const getAIRecommendations = (questionId: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.BUSINESS_PLAN.AI_SUGGESTION + "?question_id=" + questionId, {
                headers: { 'Content-Type': 'application/json', },
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.suggestion) {
                        resolve('AI recommendation received.');
                        setValue(`ai_ans_text`, resp?.suggestion);
                    } else {
                        reject('No AI recommendation available. Please try again later.');
                        // setValue(`ai_ans_text`, '');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }
    const getAIRecommendationsHandler = async (questionId: any) => {
        setAiBtnDisable(true);
        toast.promise(
            getAIRecommendations(questionId), {
            loading: 'Data generating...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    React.useEffect(() => {
        if (show) {
            getAIRecommendationsHandler(question_id);
        }
    }, [show, question_id]);

    React.useEffect(() => {
        if (aiBtnDisable) {
            setTimeout(() => {
                setAiBtnDisable(false);
            }, 5000);
        }
    }, [aiBtnDisable])


    return (
        <Offcanvas show={show} onHide={handleClose} placement={"end"} className="farming-method-offcanvas">
            <Offcanvas.Header className="aiAnsRightHeading" closeButton>
                <Offcanvas.Title>AI Assistance</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <>
                    <div className="row add-crop-block-wrapper mt-3">
                        <div key={`ai_ans_text`} className="mb-3 float-input w-100">
                            <label className="form-label">{question_txt}</label>
                            <Controller
                                defaultValue={""}
                                control={control}
                                name={`ai_ans_text`}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <FormTexAreatInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        rows={40}
                                        placeholder='Please wait for AI recommendation...'
                                    />
                                )}
                            />

                            <div className="btn-wrap-bp justify-content-end">
                                <button type="button" className="solid-btn mt-2 get_ai_recommendations"
                                    id={`AI_btnId__${question_id}`} onClick={() => getAIRecommendationsHandler(question_id)}
                                    disabled={aiBtnDisable}>
                                    {(aiBtnDisable) ? 'Generating AI Assistance...' : ' AI Assistance  '}
                                </button>
                            </div>
                        </div>
                    </div>

                </>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AIAssistanceAnsRight;

