import { useSelector } from "react-redux"
import { StateExtended } from "../interfaces/StateExtended"

export function useUser() {
    const user = useSelector((state: StateExtended) => state.user)
    return user.user
}

export function useToken(page:string = '') {
    const user = useSelector((state: StateExtended) => state.user)
    if(!user.token && (page !== 'email-auto-login' && page !== 'business-plan'&& page !== 'thank-you')) {
        window.location.href = '/login';
    }
    return user.token
}
