import React, { useEffect } from 'react';
import Nav from '../../components/layouts/nav';
import Footer from '../../components/layouts/footer';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL, URLS } from '../../_config';
import { Controller, useForm } from 'react-hook-form';
import Error from '../../components/formFieldError';
import FormTexAreatInput from '../../_common/form-elements/textareaInput';
import toast from 'react-hot-toast';
import { useUser } from '../../_common/hooks/authHook';

const ContactUs: React.FC = () => {
    let userData: any = useUser();
    const navigate: any = useNavigate();
    const { control, formState: { errors }, handleSubmit, reset } = useForm();


    const saveContactUs = (params: any) => {
        return new Promise((resolve, reject) => {

            fetch(API_URL.CMS_PAGES.CONTACT_US_SUBMIT, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        // Handle success  
                        resolve(resp.message);
                        let resetValues = { name: '', email: '', message: '' }
                        reset(resetValues);
                    } else {
                        reject(resp.message);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }


    const saveFormHandler = async (data: any) => {
        toast.promise(
            saveContactUs(data), {
            loading: 'Uploading...',
            success: (msg: any) => {

                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });


    }

    useEffect(() => {
        if (userData) {
            navigate(URLS.HOME); 
        }
    }
        , [userData]);


    return (
        <div className='bg-white'>
            <Nav />

            <div className="inner-page-banner-size contact-banner mt-105">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breathcrumb" data-aos="fade-up">
                                <ul>
                                    <li><Link to={URLS.HOME} className="de-select">Home</Link></li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                            <div className="about-text mt-40">
                                <h2 data-aos="fade-up">Got a question about <span>PlanCrafter?</span><br /> We’d love to hear from you</h2>
                                <p data-aos="fade-up">Get in touch with the PlanCrafter team and we'll get back to you and<br />help as soon as we can!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-part warm-bg">
                <div className="container">
                    <div className="sub-heading text-center mb-70 ">
                        <h2 data-aos="fade-up" className="gray-col">Contact us</h2>
                        <p data-aos="fade-up" className="gray-col">If there’s anything you’d like to know about PlanCrafter that you’ve not found on the website, please fill out the below<br />contact form. We’re always happy to answer your questions and hear your feedback.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="contact-img" data-aos="fade-right"><img src="/images/img/contact.png" alt='' /></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="contact-form">
                                <form onSubmit={handleSubmit(saveFormHandler)}>
                                    <div className="mb-4 float-input light-float" data-aos="fade-left" data-aos-duration="2000">
                                        <label className="form-label">Full Name <sup className="text-danger">*</sup></label>
                                        <Controller
                                            defaultValue={""}
                                            control={control}
                                            name={`name`}
                                            rules={{ required: "The field is required." }}
                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Enter your name" spellCheck="false" data-ms-editor="true" />
                                            )}
                                        />
                                        <Error error={errors[`name`]} customClass="text-xs" />
                                    </div>
                                    <div className="mb-4 float-input light-float" data-aos="fade-left" data-aos-duration="2000">
                                        <label className="form-label">Your email <sup className="text-danger">*</sup></label>
                                        <Controller
                                            defaultValue={""}
                                            control={control}
                                            name={`email`}
                                            rules={{ required: "The email field is required.", pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Invalid email address." } }}
                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="name@example.com" spellCheck="false" data-ms-editor="true" />
                                            )}
                                        />
                                        <Error error={errors[`email`]} customClass="text-xs" />
                                    </div>
                                    <div className="mb-4 float-input light-float" data-aos="fade-left" data-aos-duration="2000">
                                        <label className="form-label">Your message <sup className="text-danger">*</sup></label>
                                        {/* <textarea className="form-control textarea" rows={5}></textarea> */}
                                        <Controller
                                            defaultValue={""}
                                            control={control}
                                            name={`message`}
                                            rules={{ required: "The field is required." }}
                                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                <FormTexAreatInput
                                                    name={name}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputRef={ref}
                                                    type="text"
                                                    rows={3}
                                                />
                                            )}
                                        />
                                        <Error error={errors[`message`]} customClass="text-xs" />

                                    </div>
                                    <button type="submit" className="master-orng-btn w-100" data-aos="fade-left" data-aos-duration="2000">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );

};

export default ContactUs;