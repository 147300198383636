import { useEffect, useState } from "react";
import Footer from "../../components/layouts/footer";
import { API_URL, URLS } from "../../_config";
import { useToken } from "../../_common/hooks/authHook";
import toast from "react-hot-toast";
import Nav from "../../components/layouts/nav";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { STRIP_PUBLISHABLE_KEY } from "../../_config/site_urls";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const PaymentForBusinessPlan = () => {
    const [myBusinessPlan, setMyBusinessPlan] = useState<any>(null);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [paymentProposalId, setPaymentProposalId] = useState<any>(null);
    const [paymentAmount, setPaymentAmount] = useState<any>(0);
    const [couponCode, setCouponCode] = useState<any>(null);
    const navigate: any = useNavigate();
    const tokenData: any = useToken();
    const stripePromise = loadStripe(`${STRIP_PUBLISHABLE_KEY}`);

    const urlParams: any = useParams();
    const proposalId = urlParams?.proposalId;

    const getMyBusinessPlanDetails = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN_DETAILS + "/" + proposalId, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            setMyBusinessPlan(resp.data)
        } catch (error) {
            setMyBusinessPlan([]);
            console.log('Error occurred while making the API request.');
        }
    }


    useEffect(() => {
        getMyBusinessPlanDetails();
    }, [])


    const getPaymentIntent = (params: any, proposalId: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.PAYMENTS.STRIPE_INTENT, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) { // Success
                        setPaymentProposalId(proposalId);
                        setPaymentAmount(params.amount);
                        // resolve(resp.message);
                        resolve("Form is ready to payment.");
                        setClientSecret(resp.data.client_secret);
                    } else {
                        reject(resp.message);
                        setClientSecret('')
                    }
                })
                .catch(error => {
                    reject(error.message);
                    setClientSecret('')
                });
        })
    }

    const paymentIntentHandler = async (data: any, proposalId: any) => {
        toast.promise(
            getPaymentIntent(data, proposalId), {
            loading: 'Checking...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        if (myBusinessPlan && myBusinessPlan.status == 3 && myBusinessPlan?.payment_status != 1) {
            const payAmount = (myBusinessPlan?.discount > 0) ? myBusinessPlan?.discount : myBusinessPlan.amount
            paymentIntentHandler({ amount: (parseFloat(payAmount) * 100), currency: myBusinessPlan.currency }, myBusinessPlan.id);
        }
    }, [myBusinessPlan])
 
    return (

        <>
            <Nav />
            <div className="body-part gray-bg mt-105">
                <div className="container text-center">

                    <Breadcrumbs title={`Payment For Business Plan`}
                        breadcrumbs={{
                            'Home': URLS.HOME,
                            'My Business Plan': URLS.ACCOUNT.MY_BUSINESS_PLAN,
                            'Payment': '#',
                        }} />
                    <h2 className="page-title"><span>Complete Your</span> Payment</h2>
                    <p className="tag-line">Complete your payment to get Business Plan</p>
                    <p className="tag-line">
                        {myBusinessPlan && myBusinessPlan?.subscription_plan?.subscription_plan_type?.title}
                        - <strong>{myBusinessPlan && myBusinessPlan.subscription_plan?.title}</strong>
                    </p>

                    <div className="profile-box">
                        <div className="mt-5">
                            {
                                myBusinessPlan && myBusinessPlan.status == 3 && myBusinessPlan?.payment_status != 1 &&
                                <>
                                    {
                                        stripePromise && clientSecret && paymentProposalId &&
                                        <Elements stripe={stripePromise}  >
                                            <CheckoutForm propData={{ clientSecret, paymentProposalId, paymentAmount }} /> 
                                        </Elements>
                                    }
                                </>
                            }
                        </div>
                        <div className="profile-box-data justify-content-center">

                            <div className="text-center mt-5">
                                {
                                    myBusinessPlan?.status < 3 && <p >Proposal is not ready for payment.</p>
                                }
                                {
                                    myBusinessPlan?.payment_status == 1 && <p>Payment already done.</p>
                                }
                                {
                                    myBusinessPlan == null && <p>Loading...</p>
                                }

                                {
                                    myBusinessPlan && myBusinessPlan.length == 0 && <p>No data found.</p>
                                }

                                <br />
                                <button type="button" className="master-orng-btn mt-3" onClick={() => navigate(URLS.ACCOUNT.MY_BUSINESS_PLAN)}>Back to My business plan</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default PaymentForBusinessPlan; 