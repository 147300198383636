import React, { useEffect, useState } from 'react';
import Nav from '../../components/layouts/nav';
import Footer from '../../components/layouts/footer';
import { API_URL, URLS } from '../../_config';
import { Link } from 'react-router-dom';

const AboutUs: React.FC = () => {
    const [cmsContantData, setCmsContantData] = useState<any>(null);

    const getCmsContentData = async () => {
        try {
            const response = await fetch((API_URL.HOME_CONTENT_DATA + 'about'));
            const resp = await response.json();
            setCmsContantData(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            setCmsContantData([])
        }
    }

    useEffect(() => {
        getCmsContentData();
    }, [])

    return (
        <div className='bg-white'>
            <Nav />

            <div className="about-banner inner-page-banner-size mt-105">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breathcrumb" data-aos="fade-up">
                                <ul>
                                    <li><Link to={URLS.HOME} className="de-select">Home</Link></li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>About</li>
                                </ul>
                            </div>
                            {
                                cmsContantData?.about_1 &&
                                <div className="about-text mt-40">
                                    <h2 data-aos="fade-up">
                                        {cmsContantData?.about_1?.title.split(' ').map((word: string, index: number) => {
                                            return (index === 1) ? <span key={index}>{` ${word}`}</span> : ' ' + word
                                        })}
                                    </h2>
                                    <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsContantData?.about_1?.long_description }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-part warm-bg">
                <div className="container">
                    <div className="sub-heading-inner text-center mb-70">
                        <h2 data-aos="fade-up" >A little <span>guidance,</span>achieve almost<br />any <span>business</span> goal</h2>
                    </div>
                    <div className="row position-relative">
                        {
                            cmsContantData?.about_2 &&
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="business-text" data-aos="fade-right">
                                    <div className="business-text-img"><img src="/images/img/b1.svg" alt='' /></div>
                                    <div className="business-text-inner">
                                        <h3>{cmsContantData?.about_2?.title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: cmsContantData?.about_2?.long_description }} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-lg-4 col-md-12 col-12"><div className="guide-img" data-aos="zoom-in"><img src="/images/img/man-obj.png" alt='' /></div></div>

                        {
                            cmsContantData?.about_3 &&
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="business-text" data-aos="fade-left">
                                    <div className="business-text-img"><img src="/images/img/b2.svg" alt='' /></div>
                                    <div className="business-text-inner">
                                        <h3>{cmsContantData?.about_3?.title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: cmsContantData?.about_3?.long_description }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="history-sec">
                <div className="container">
                    <div className="row align-items-center mob-flip">
                        {
                            cmsContantData?.our_history &&
                            <div className="col-lg-8 col-md-8 col-12 mb-50 pe-5">
                                <div className="section-para">
                                    <h2 data-aos="fade-up">
                                        {cmsContantData?.our_history?.title.split(' ').map((word: string, index: number) => {
                                            return (index === 1) ? <span key={`our_history-${index}`}>{` ${word}`}</span> : ' ' + word
                                        })}
                                    </h2>
                                    <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsContantData?.our_history?.long_description }} />
                                </div>
                            </div>
                        }
                        <div className="col-lg-4 col-md-4 col-12 mb-50">
                            <div className="section-para-img" data-aos="fade-left">
                                <img src="/images/img/our-history.png" alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-12 ">
                            <div className="section-para-img" data-aos="fade-right">
                                <img src="/images/img/our-team.png" alt='' />
                            </div>
                        </div>
                        {
                            cmsContantData?.our_team &&
                            <div className="col-lg-8 col-md-8 col-12 ps-5">
                                <div className="section-para">
                                    <h2 data-aos="fade-up">
                                        {cmsContantData?.our_team?.title.split(' ').map((word: string, index: number) => {
                                            return (index === 1) ? <span key={`our_team-${index}`}>{` ${word}`}</span> : ' ' + word
                                        })}
                                    </h2>
                                    <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsContantData?.our_team?.long_description }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                cmsContantData?.about_4 &&
                <div className="get-started-color">
                    <div className="container">
                        <div className="get-started">
                            <h2 data-aos="fade-up">{cmsContantData?.about_4?.title}</h2>
                            <h3 data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsContantData?.about_4?.long_description }} />
                            <Link to={URLS.SUBSCRIPTION} className="master-orng-btn black-btn" data-aos="zoom-in">Start Your Business Plan</Link>
                            {/* <button type="button" className="master-orng-btn black-btn" data-aos="zoom-in">Start Your Business Plan</button> */}
                        </div>
                    </div>
                </div>
            }

            <Footer />
        </div>
    );
};

export default AboutUs;