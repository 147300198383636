import React from 'react';
import Nav from '../../components/layouts/nav';
import Footer from '../../components/layouts/footer';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../_config';
import { replaceUrlParams } from '../../_common/functions';

const Cms: React.FC = () => {
    const [cms, setCms] = React.useState<any>(null);
    const { cmsSlug } = useParams<{ cmsSlug: string }>();

    console.log(cmsSlug)
    const getCmsData = async () => {
        try {
            const response = await fetch(replaceUrlParams(API_URL.CMS_PAGES.CMS_LINK, { cmsSlug: cmsSlug }));
            const resp = await response.json();
            console.log(resp.data);
            setCms(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setCms([])
        }
    }

    React.useEffect(() => {
        getCmsData()
    }, [cmsSlug]);


    return (
        <>
            <Nav />
            <div className="subscription">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner-text">
                                <h2 data-aos="fade-up">{cms?.title}</h2>
                                <p data-aos="fade-up">{cms?.short_description}</p>
                                {/* <!-- <a href="#" data-aos="zoom-in"><button type="button" className="master-orng-btn xl-orng-btn">Start Your Business Plan</button></a> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="body-part bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 d-none">
                            <div className="about_image" data-aos="fade-right" data-aos-duration="2000">
                                <img src="img/about.jpg" alt='' />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="sub-heading mb-50 " data-aos="fade-left" data-aos-duration="2000">
                                <span >{cms?.title}</span>
                                {/* <h2>Our Story</h2> */}
                                <div dangerouslySetInnerHTML={{ __html: cms?.long_description }} />

                            </div>
                        </div>
                    </div>

                </div>

                <div className='text-center'>
                    {
                        cms && cms.length === 0 && <p>No data found!</p>
                    }
                    {
                        !cms && <p>Loading...</p>
                    }
                </div>

            </div>

            <div className="our_team d-none">
                <div className="container">
                    <div className="sub-heading text-center mb-50">
                        <h2 data-aos="fade-up" className="aos-init aos-animate">Our Team</h2>
                        <p data-aos="fade-up" className="aos-init aos-animate">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card mb-4 box-shadow" data-aos="fade-up" data-aos-duration="2000">
                                <img className="card-img-top" src="img/about_01.jpg" alt='' />
                                <div className="card-body">
                                    <h3 className="card-title">Smith</h3>
                                    <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card mb-4 box-shadow" data-aos="fade-up" data-aos-duration="2000">
                                <img className="card-img-top" src="img/about_02.jpg" alt='' />
                                <div className="card-body">
                                    <h3 className="card-title">Jennifer</h3>
                                    <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card mb-4 box-shadow" data-aos="fade-up" data-aos-duration="2000">
                                <img className="card-img-top" src="img/about_03.jpg" alt='' />
                                <div className="card-body">
                                    <h3 className="card-title">Nancy</h3>
                                    <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card mb-4 box-shadow" data-aos="fade-up" data-aos-duration="2000">
                                <img className="card-img-top" src="img/about_04.jpg" alt='' />
                                <div className="card-body">
                                    <h3 className="card-title">Mary</h3>
                                    <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
};

export default Cms;