import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { API_URL, URLS } from "../../_config"
import toast from "react-hot-toast";
import Error from "../../components/formFieldError";
import { Controller, useForm } from "react-hook-form";
import { APP_NAME, LOGO_URL } from "../../_config/site_urls";

const SignUp = () => {
    const navigate: any = useNavigate();
    const { control, formState: { errors }, handleSubmit, setError } = useForm();

    const submitSignUp = (params: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.AUTH.SIGN_UP, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        // Handle success
                        console.log('Response:', resp);
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                        const errorResp = resp?.data;
                        if (errorResp) {
                            Object.keys(errorResp).map((key) => {
                                setError(key, {
                                    type: "manual",
                                    message: errorResp[key]
                                })
                            })
                        }
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }


    const saveFormHandler = async (data: any) => {
        toast.promise(
            submitSignUp(data), {
            loading: 'Submitting...',
            success: (msg: any) => {
                localStorage.setItem("loginEmail", data.email);
                navigate(URLS.LOGIN);

                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }
 

    return (
        <React.Fragment>
            <div className="login-bg"></div>
            <div className="login-box">
                <div className="login-box-left">
                    <Link to={URLS.HOME} className="logo-login"><img src={LOGO_URL} alt={APP_NAME} /></Link>
                    <div className="login-text" data-aos="fade-up">
                        <h2>Hey There!</h2>
                        <p>Welcome Back.</p>
                        <p>You are just one step away to your feed</p>
                    </div>
                    <div className="sign-up-box" data-aos="fade-up">
                        <h3>You already have an account?</h3>
                        <Link className="outline-btn-plan-craft" to={URLS.LOGIN}>Log In</Link>
                    </div>
                </div>
                <div className="login-box-right">
                    <h2>Sign-up to <span>PlanCrafter</span></h2>
                    <p>Enter your details please</p>
                    <div data-aos="fade-up" className="form-wrap">
                        <form onSubmit={handleSubmit(saveFormHandler)} className="col-md-12 row">
                            <div className="col-lg-6 col-md-12 mb-3 float-input">
                                <label className="form-label">First Name <span className="text-danger">*</span></label>
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name={`first_name`}
                                    rules={{ required: "First name is required." }}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Enter first name" spellCheck="false" data-ms-editor="true" />
                                    )}
                                />
                                <Error error={errors[`first_name`]} customClass="text-xs" />
                            </div>
                            <div className="col-lg-6 col-md-12 mb-3 float-input">
                                <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name={`last_name`}
                                    rules={{ required: "Last name is required." }}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Enter last name" spellCheck="false" data-ms-editor="true" />
                                    )}
                                />
                                <Error error={errors[`last_name`]} customClass="text-xs" />
                            </div>

                            <div className="col-lg-6 col-md-12 mb-3 float-input">
                                <label className="form-label">Email <span className="text-danger">*</span></label>
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name={`email`}
                                    rules={{
                                        required: "Email is required.", pattern: {
                                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: "Invalid email address.",
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="name@example.com" spellCheck="false" data-ms-editor="true" />
                                    )}
                                />
                                <Error error={errors[`email`]} customClass="text-xs" />
                            </div>
                            <div className="col-lg-6 col-md-12 mb-3 float-input">
                                <label className="form-label">Phone <span className="text-danger">*</span></label>
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name={`phone`}
                                    rules={{ required: "Phone is required." }}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Enter phone" spellCheck="false" data-ms-editor="true" />
                                    )}
                                />
                                <Error error={errors[`phone`]} customClass="text-xs" />
                            </div>
                            <button type="submit" className="primary-btn-plan-craft w-100">Sign Up</button>
                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default SignUp