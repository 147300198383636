import { Link } from "react-router-dom";
import Nav from "../../components/layouts/nav";
import Footer from "../../components/layouts/footer";
import React, { useEffect, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
// import { fetchApi } from "../../_common/hooks/api";
import { API_URL, URLS } from "../../_config";
import { replaceUrlParams } from "../../_common/functions";


const Subscription = () => {
    const [subscriptionPlans, setSubscriptionPlans] = useState<any>(null);

    const queryParams = new URLSearchParams(window.location.search);
    const selectSubsId = queryParams.get("id");

    const [activeTab, setActiveTab] = useState<any>((selectSubsId) ? selectSubsId : 0);

    const getAreaPictures = async () => {
        // fetchApi.GET(API_URL.SUBSCRIPTION.PLAN_LIST, {}).then((resp: any) => {
        //     console.log(resp)
        //     setSubscriptionPlans(resp)
        // }).catch((e: any) => {
        //     console.log(e)
        // })

        try {
            const response = await fetch(API_URL.SUBSCRIPTION.PLAN_LIST);
            const resp = await response.json();
            console.log(resp.data);
            setSubscriptionPlans(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
        }
    }

    useEffect(() => {
        getAreaPictures();
    }, [])

    return (
        <>
            <Nav />
            <div className="subscription">
                <div className="container-fluid">
                    <h2 data-aos="fade-down" data-aos-duration="1000">Subscription Plan</h2>
                    {/* <p data-aos="fade-down" data-aos-duration="1000">
                        Lorem Ipsum is simply dummy text of the printing and typesetting <br />industry. Lorem Ipsum has been the industry's standard.
                    </p> */}

                    <div className="subscribe_content">
                        <div defaultValue={1}>
                            <div className="nav nav-tabs">
                                {subscriptionPlans && subscriptionPlans.map((subscription: any, i: number) => (
                                    <div key={`subscription.title ${subscription.title}`} className="nav-item cursor-pointer" onClick={() => setActiveTab(subscription.id)}>
                                        <span className={`nav-link ${(activeTab == 0 && i == 0) ? 'active' : (activeTab == subscription.id) ? 'active' : ''}`}>
                                            {subscription.title}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-3">
                                {subscriptionPlans && subscriptionPlans.map((subscription: any, _i:number) => (
                                    <div key={`tab_${subscription?.title}`} className={`p-3 ${(activeTab == 0 && _i == 0) ? 'active' : (activeTab == subscription.id) ? 'active' : 'd-none'}`}>
                                        <div className="subscription_list">
                                            {
                                                subscription?.subscription_plans.length === 0 && <p>No subscription plan available!</p>
                                            }
                                            {subscription?.subscription_plans.map(({ id, title, icon, features, price }: { id: number, title: string, icon: any, features: any[], price: any }) => (
                                                <div className="subscription_plan active" key={`subscription_plans_${id}`}>
                                                    <div className="subscription_plantitle">
                                                        <div className="plan_icon ">
                                                            <img src={icon?.thumb} alt="" width={'35px'} />
                                                        </div>
                                                        <h3>
                                                            {title.split(" ").map((word: string, index: number) => (
                                                                index === 0 ? <span key={`totle_${index}`}>{word.toUpperCase()} </span> : <React.Fragment key={`totle_${index}`}>{word.toUpperCase()} </React.Fragment>
                                                            ))}
                                                        </h3>
                                                        <h4 className="subscription_plan_price">{price ? price : '0.00'} USD</h4>
                                                    </div>
                                                    <div className="subscription_planlist">
                                                        <ul>
                                                            {features.map((_featureItem: any) => (
                                                                <li key={`features${_featureItem.title}`}>{_featureItem.title}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="select_planbtnctrl">
                                                        <Link to={replaceUrlParams(URLS.ADDONS, { subscriptionId: id }) + "?sId="+subscription?.id }><button className="select_plan">Select Plan</button></Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                    {/* <div className="subscribe_content">
                        <TabGroup defaultValue={activeTab}>
                            <TabList className="nav nav-tabs">
                                {subscriptionPlans && subscriptionPlans.map((subscription: any, i: number) => (
                                    <Tab key={`subscription.title ${subscription.title}`} className="nav-item" onClick={() => setActiveTab(subscription.id)}>
                                        <span className={`nav-link ${(activeTab == subscription.id) ? 'active' : ''}`}>
                                            {subscription.title}</span>
                                    </Tab>
                                ))}
                            </TabList>
                            <TabPanels className="mt-3">
                                {subscriptionPlans && subscriptionPlans.map(({ id, title, subscription_plans }: { id: number, title: string, subscription_plans: any[] }) => (
                                    <TabPanel key={`tab_${title}`} className={`p-3 ${(activeTab == id) ? 'active' : ''}`}>
                                        <div className="subscription_list">
                                            {subscription_plans.map(({ id, title, icon, features }: { id: number, title: string, icon: any, features: any[] }) => (
                                                <div className="subscription_plan" key={`subscription_plans_${id}`}>
                                                    <div className="subscription_plantitle">
                                                        <div className="plan_icon ">
                                                            <img src={icon?.thumb} alt="" width={'35px'} />
                                                        </div>
                                                        <h3>
                                                            {title.split(" ").map((word: string, index: number) => (
                                                                index === 0 ? <span key={`totle_${index}`}>{word.toUpperCase()} </span> : <React.Fragment key={`totle_${index}`}>{word.toUpperCase()} </React.Fragment>
                                                            ))}
                                                        </h3>
                                                    </div>
                                                    <div className="subscription_planlist">
                                                        <ul>
                                                            {features.map((_item: any) => (
                                                                <li key={`features${_item.title}`}>{_item.title}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="select_planbtnctrl">
                                                        <Link to={replaceUrlParams(URLS.ADDONS, { subscriptionId: id })}><button className="select_plan">Select Plan</button></Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </TabGroup>
                    </div> */}

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Subscription;